import ghImg from '../assets/gh.jpg'
import logo from '../logo.svg'

export default function Porfolio({resumeData}) {
  return (
    <section id='portfolio'>
      <div className='row'>
        <div className='twelve columns collapsed'>
          <h1>
            Check out some of my personal
            <img src={logo} className='App-imgReact' alt='reactjs' />
            projects
          </h1>
          <div id='portfolio-wrapper' className='bgrid-quarters s-bgrid-thirds cf' alt=''>
            {/* {
              resumeData.portfolio && resumeData.portfolio.map((item) => {
                return (
                  <div className='columns portfolio-item'>
                    <div className='item-wrap'>
                      <a href='#modal-01'>
                        <img src={`${item.imgurl}`} className='item-img' />
                        <div className='overlay'>
                          <div className='portfolio-item-meta'>
                            <h5>{item.name}</h5>
                            <p>{item.description}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                )
              })
            } */}
            <a
              className='App-link'
              href='https://www.addagames.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={ghImg} className='App-imgGh' alt='gloomhaven' /><br />
                GloomHaven Revealer App
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}