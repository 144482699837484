export default function About({resumeData}) {
  return (
    <section id='about'>
      <div className='row'>
        <div className='three columns'>
          <img className='profile-pic' src='images/profile.jpg' alt='' />
        </div>
        <div className='nine columns main-col'>
          <h2>About Me</h2>
          <p>{resumeData.aboutme}</p>
          <div className='row'>
            <div className='columns contact-details'>
              <h2>Contact Details</h2>
              <p className='address'>
                <span>{resumeData.fullname}</span>
                <br></br>
                <span>
                  {resumeData.address}
                </span>
                {/* <br/>
                <span><a href={resumeData.website}>{resumeData.website}</a></span> */}
                <br/>
                <span><a href={`mailto: ${resumeData.email}`}>{resumeData.email}</a></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}