import About from './components/About.jsx'
import ContactMe from './components/ContactMe.jsx'
import Footer from './components/Footer.jsx'
import Header from './components/Header.jsx'
import Portfolio from './components/Portfolio.jsx'
import Resume from './components/Resume.jsx'
import resumeData from './resumeData'
import './App.css'

function App() {
  return (
    <div className='App'>
      <Header resumeData={resumeData} />
      <About resumeData={resumeData} />
      <Resume resumeData={resumeData} />
      <Portfolio resumeData={resumeData} /><br/>
      <ContactMe resumeData={resumeData} />
      <Footer resumeData={resumeData} />
    </div>
  )
}

export default App
