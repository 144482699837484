let resumeData = {
  imagebaseurl: 'https://ivy555.github.io/',
  name: 'Bryan',
  fullname: 'Bryan McGinnis',
  email: 'bryanrm@gmail.com',
  role: 'Front-End/Full Stack Developer',
  // linkedinId: 'Your ID',
  skypeid: 'Your skypeid',
  roleDescription: 'seeking a new opportunity',
  socialLinks: [
    // {
    //   name: 'linkedin',
    //   url: 'https://www.linkedin.com/in/bryan-mcginnis-40928620/',
    //   className: 'fa fa-linkedin'
    // },
    // {
    //   name: 'github',
    //   url: 'http://github.com/ivy555',
    //   className: 'fa fa-github'
    // },
    // {
    //   name: 'skype',
    //   url: '',
    //   className: 'fa fa-twitter'
    // }
  ],
  aboutme: `
    I have been doing software programming since I was very young.
    In college I switched gears and studied Chemistry, Biology, and Electrical Engineering.
    Then I came back to my computer and programming love.
    I have experience as a Full Stack and Front-End Web Developer.
    I am also doing some for fun projects, including learning BlockChain technology.
  `,
  address: 'Orem, Utah',
  // website: 'https://www.bryanmcginnis.com/',
  linkedInLink: 'https://www.linkedin.com/in/bryan-mcginnis-40928620/',
  education: [
    {
      UniversityName: 'Arizona State University',
      specialization: 'M.S. Electrical Engineering Technology',
      YearOfPassing: '2009',
    },
    {
      UniversityName: 'BYU Idaho',
      specialization: 'B.S. Chemistry',
      YearOfPassing: '2005',
    }
  ],
  work: [
    {
      CompanyName: 'Pura Scents',
      specialization: 'Senior Full-Stack Engineer',
      startYear: '2020',
      endYear: '2021',
      Achievements: 'JavaScript, NodeJS, ReactJS, AWS'
    },
    {
      CompanyName: 'Sling TV',
      specialization: 'Senior Front-end Developer',
      startYear: '2018',
      endYear: '2020',
      Achievements: 'ReactJS, AngularJS, JavaScript, DevOps'
    },
    {
      CompanyName: 'Legacy Family Tree',
      specialization: 'Full-Stack Engineer',
      startYear: '2013',
      endYear: '2017',
      Achievements: 'PHP, AngularJS, JavaScript, Windows/Linux Server Management, Database Management'
    }
  ],
  skillsDescription: 'Your skills here',
  skills: [
    {
      skillname: 'HTML5'
    },
    {
      skillname: 'CSS'
    },
    {
      skillname: 'Reactjs'
    }
  ],
  portfolio: [
    {
      name: 'project1',
      description: 'mobileapp',
      imgurl: 'images/portfolio/phone.jpg'
    },
    {
      name: 'project2',
      description: 'mobileapp',
      imgurl: 'images/portfolio/project.jpg'
    },
    {
      name: 'project3',
      description: 'mobileapp',
      imgurl: 'images/portfolio/project2.png'
    },
    {
      name: 'project4',
      description: 'mobileapp',
      imgurl: 'images/portfolio/phone.jpg'
    }
  ],
}
export default resumeData